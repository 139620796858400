const iconElement = document.getElementById("hamburger-icon-js");
const menuElement = document.getElementById("hamburger-menu-js");

iconElement.addEventListener("click", () => {
  const isOpen = menuElement.dataset.isOpen;
  if (isOpen === "true") {
    iconElement.dataset.isOpen = "false";
    menuElement.dataset.isOpen = "false";
  } else {
    iconElement.dataset.isOpen = "true";
    menuElement.dataset.isOpen = "true";
  }
});

const menuItems = document.querySelectorAll(".hamburger-menu__item");
for (let i = 0; i < menuItems.length; i++) {
  menuItems[i].addEventListener("click", () => {
    if (menuItems[i].dataset.link) {
      window.location.href = menuItems[i].dataset.link;
    }
    iconElement.dataset.isOpen = "false";
    menuElement.dataset.isOpen = "false";
  });
}

document.addEventListener("click", (event) => {
  if (!menuElement.contains(event.target) && !iconElement.contains(event.target)) {
    const isOpen = menuElement.dataset.isOpen;
    if (isOpen === "true") {
      iconElement.dataset.isOpen = "false";
      menuElement.dataset.isOpen = "false";
    }
  }
});
